import * as styles from './Note.module.scss';
import cn from 'classnames';

export const Note = ({ className, placeholder, value, onChange }: { className?: string, placeholder?: string, value: string, onChange?: (value: string) => void }) => {
    const translations = window.app.preloadState.translation;

    return <div className={cn(styles.note, className)}>
        <p>{translations["machineSalesCheckout.doYouHaveANote"]}</p>
        <textarea rows={5} placeholder={placeholder ?? translations["machineSalesCheckout.enterTextHere"]} value={value} onChange={ev => onChange?.(ev.target.value)} />
    </div>;
}